html { 
   height: 100% 
}
body { 
   margin: 0; 
   padding: 0; 
   height: 100% ;
}
.close-x-div {
   height: 100%;
   padding: 20px;
   box-sizing: border-box;
}
.close-x-parent {
   height: 80px;
   display: flex;
   justify-content: flex-end;
}
.close-x-svg {
   cursor: pointer;
}

.nav-item {
   font-size: 1.8rem;
	font-family: 'RobotoMono', sans-serif;
	text-transform: uppercase;
   letter-spacing: .38rem;
   margin-left: 18px;
   margin-bottom: 25px;
}

.nav-list {
   margin-top: 15px;
}

.nav-text {
   cursor: pointer;
}

.nav-text:link {
   color: black;
   text-decoration: none;
}
.nav-text:visited {
   color: black;
   text-decoration: none;
}
.nav-text:hover {
   text-decoration: underline;
}
.nav-text:active {
   text-decoration: underline;
}

.side-drawer { 
   height: 100%;
   background: white;
   position: fixed;
   top: 0;
   right: 0;
   width: 40%;
   z-index: 200;
   transform: translateX(100%);
   transition: transform 0.3s ease-out;
}
.side-drawer.open {
   transform: translateX(0);
   box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
}

@media (max-width:399px) {
   .side-drawer {
      width: 100%;
   }
}

@media (min-width:400px) and (max-width:575px) {
   .side-drawer {
      width: 100%;
   }
}

@media (min-width:576px) and (max-width:767px) {
   .side-drawer {
      width: 55%;
   }
}