/* The Modal (background) */
.modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
  
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 50px auto; /* 50px from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 70%; /* Could be more or less, depending on screen size */
}

/* loading spinner */
.loader {
  border: 4px solid #e2e2e2;
  border-radius: 50%;
  border-top: 4px solid black;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 auto
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal-button-div {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  margin: 20px 0 0 15px;
  outline: none;
  transition-duration: 0.3s;
  box-sizing: border-box;
  border-radius: 0;
  background-color: white;
  height: 40px;
  width: 100px;
  cursor: pointer;
  border: 1px black solid;
}

.modal-close-button:hover {
  background-color: black;
  color: white;
}

.modal-file-div {
  border: 1px solid black;
  width: 100%;
  display: flex;
  box-sizing: border-box;
}

.modal-file-label {
  outline: none;
  transition-duration: 0.3s;
  box-sizing: border-box;
  border-radius: 0;
  background-color: white;
  overflow: hidden;
  position: relative;
  border-left: 1px solid black;
  display: inline-block;
  padding: 10px 12px;
  cursor: pointer;
  line-height: normal;
}

.modal-file-label:hover {
  background-color: black;
  color: white;
}

/* .modal-file-label:after {
  content: "";
  background: rgb(105, 105, 105);
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px!important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s
}

.modal-file-label:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
} */

.modal-file-text-area {
  flex:1;
  padding: 6px 12px;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-input-file {
  display: none;
}

.modal-instructions {
  margin: 12px 0;
}

.modal-links-input {
  width: 100%;
  padding: 12px 12px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid black;
  outline:none;
  height: 40px;
}

.modal-setlist-input {
  max-width: 300px;
  padding: 12px 12px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid black;
  outline:none;
  height: 40px;
}

.modal-setlist-label {
  width: 30px;
  display: inline-block;
}

.modal-submit-button {
  margin: 20px 0 0 15px;
  outline: none;
  transition-duration: 0.3s;
  box-sizing: border-box;
  height: 40px;
  width: 100px;
  border-radius: 0;
  cursor: pointer;
  border: 1px black solid;
  background-color: black;
  overflow: hidden;
  position: relative;
  color: white;
}

.modal-submit-button:hover {
  background-color: white;
  color: black;
}

/* .modal-submit-button:after {
  content: "";
  background: rgb(197, 197, 197);
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px!important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s
}

.modal-submit-button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
} */

.modal-submit-button:disabled {
  background-color: white;
  color: black;
}

@media (max-width: 399px) {
  .modal-content {
    width: 80%;
  }
  
}

@media (min-width: 400px) and (max-width: 575px) {
  .modal-content {
    width: 80%;
  }
}