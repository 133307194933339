.hamburger-div {
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    height: 100%;
    box-sizing: border-box;
}

.hamburger-root {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    max-width: 1200px;
    margin: 0 auto;
}

.test-click {
    position: relative;
    height: 100%;
    cursor: pointer;
}

.test-click:after {
    content: '';
    position: absolute;
    left: -10px;
    top: -10px;
    bottom: -10px;
    right: -10px;
    /* background: #e83474; */
}