/* Change the white to any color ;)
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
} */

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-position: calc(100% - 17px) center;
    background-repeat: no-repeat;
    background-color: transparent;
}

.form-row label {
    margin: 10px 0;
    display: inline-block;
}

.all-shows-button {
    outline: none;
    transition-duration: 0.3s;
    box-sizing: border-box;
    height: 50px;
    width: 200px;
    border-radius: 0;
    margin-top: 44px;
    cursor: pointer;
    border: 1px black solid;
    background-color: white;
    overflow: hidden;
    position: relative;
}

.all-shows-button:hover {
    background-color: black;
    color: white;
}

.all-shows-button:after {
    content: "";
    background: rgb(105, 105, 105);
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px!important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}
  
.all-shows-button:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

.form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.form-row input {
    width: 200px;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid black;
    outline:none;
    height: 50px;
}

.form-row select {
    width: 200px;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid black;
    outline:none;
    height: 50px;
}

.resize-animation-stopper * {
    transition: none !important;
    animation: none !important;
}

.search-button {
    outline: none;
    transition-duration: 0.3s;
    box-sizing: border-box;
    height: 50px;
    width: 200px;
    border-radius: 0;
    margin-top: 44px;
    cursor: pointer;
    border: 1px black solid;
    background-color: black;
    overflow: hidden;
    position: relative;
    color: white;
}

.search-button:hover {
    background-color: white;
    color: black;
}

.search-button:after {
    content: "";
    background: rgb(197, 197, 197);
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px!important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}
  
.search-button:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

.search-button-div {
    margin-left: auto;
}

.search-form {
    margin-top: 20px;
}

@media (max-width: 399px) {
    .form-row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .search-button-div {
        margin-left: 0;
    }
}

@media (min-width: 400px) and (max-width: 575px) {

    .all-shows-button {
        width: 180px;
    }

    .form-row input {
        width: 180px;
    }

    .form-row select {
        width: 180px;
    }

    .search-button {
        width: 180px;
    }
}

@media (min-width: 950px) {
    
    .all-shows-button {
        width: 250px;
    }

    .form-row input {
        width: 250px;
    }

    .form-row select {
        width: 250px;
    }

    .search-button {
        width: 250px;
    }
}