.admin-button {
    padding: 2px 6px;
}

.admin-clickable {
    cursor: pointer;
}
.admin-clickable:hover {
    text-decoration: underline;
    color: blue;
}
.admin-flyer {
    max-width: 500px;
    max-height: 500px;
}
.admin-margin-bottom {
    margin-bottom: 10px;
}