.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    
    z-index: -100;
    top: 0;
    right: 0;
    transition: background-color .3s linear;
}

.backdrop.open {
    background-color: rgba(0,0,0,0.5);
    z-index:100;
}