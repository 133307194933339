.full-show-add-button {
    outline: none;
    transition-duration: 0.3s;
    box-sizing: border-box;
    height: 24px;
    width: 48px;
    border-radius: 0;
    cursor: pointer;
    border: 1px black solid;
    background-color: black;
    overflow: hidden;
    position: relative;
    color: white;
    align-self: center;
    margin-left: 14px;
    /* margin-bottom: 20px; */
}

.full-show-add-button:hover {
    background-color: white;
    color: black;
}

.full-show-add-button:after {
    content: "";
    background: rgb(197, 197, 197);
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px!important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}
  
.full-show-add-button:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

.full-show-address-title {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
}

.full-show-city {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 5;
}

.full-show-date {
    grid-column-start: 1;
    grid-column-end: 3;
}

.full-show-date-heading {
    padding-bottom: 8px;
}

.full-show-flyer-div {
    width: 310px
}

.full-show-flyer-set-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.full-show-grid-container {
    display: grid;
    grid-template-columns: 290px 300px 60px;
    justify-content: center;
    grid-template-rows: 30px auto auto 33px;
    /* cursor: pointer; */
}

.full-show-header-margin {
    margin: 14px 0 8px 0;
    display: flex;
}

.full-show-image {
    width: 100%;
    border: 1px solid rgb(224, 224, 224);
}

.full-show-info-border {
    width: 654px;
    border-bottom: lightgray 1px solid;
    margin: 0 auto;
}

.full-show-info-container {
    padding-top: 10px;
    padding-bottom: 30px;
    line-height: 1.4;
    width: 654px;
    margin: 0 auto;
}

/* .full-show-info-container:hover {
    background-color: rgb(248, 248, 248);
} */

.full-show-links-details {
    flex: 1;
    min-width: 0;
    overflow-wrap: break-word;
}

.full-show-links-details > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.full-show-link-div {
    display: flex;
    margin-bottom: 8px;
}

.full-show-links-link:link {
    color: black;
    text-decoration: none;
}
.full-show-links-link:visited {
    color: black;
    text-decoration: none;
}
.full-show-links-link:hover {
    text-decoration: underline;
}
.full-show-links-link:active {
    text-decoration: underline;
}

.full-show-links-map-div {
    margin-bottom: 8px;
}

.full-show-link-title-col {
    width: 100px;
}

.full-show-margin-bottom {
    margin-bottom: 7px;
}

.full-show-num {
    grid-column-start: 3;
    grid-column-end: 4;
}

.full-show-set-list-div {
    width: 310px;
}

.full-show-slight-bold {
    font-weight: 600;
}

.full-show-street {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
}

.full-show-venue {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}

.full-show-venue-title {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
}

@media (max-width:399px) {
    .full-show-address-title {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 4;
        grid-row-end: 5;
    }
    
    .full-show-city {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 6;
        grid-row-end: 7;
    }
    
    .full-show-date {
        grid-column-start: 1;
        grid-column-end: 2;
    }
    
    .full-show-grid-container {
        grid-template-columns: 227px 55px;
        grid-template-rows: 30px auto auto auto auto 33px;
    }
    
    .full-show-info-border {
        width: 286px;
    }
    
    .full-show-info-container {
        width: 286px;
    }

    .full-show-link-div {
        flex-direction: column;
    }
    
    .full-show-num {
        grid-column-start: 2;
        grid-column-end: 3;
    }
    
    .full-show-street {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 5;
        grid-row-end: 6;
    }
    
    .full-show-venue {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
    }
    
    .full-show-venue-title {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }
}

@media (min-width: 400px) and (max-width: 575px) {
    .full-show-address-title {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 4;
        grid-row-end: 5;
    }
    
    .full-show-city {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 6;
        grid-row-end: 7;
        margin-left: 20px;
    }
    
    .full-show-date {
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .full-show-flyer-div {
        width: 100%;
    }
    
    .full-show-grid-container {
        grid-template-columns: 311px 55px;
        grid-template-rows: 30px auto auto auto auto 33px;
    }
    
    .full-show-info-border {
        width: 370px;
    }
    
    .full-show-info-container {
        width: 370px;
    }
    
    .full-show-num {
        grid-column-start: 2;
        grid-column-end: 3;
    }

    .full-show-set-list-div {
        width: 100%;
    }
    
    .full-show-street {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 5;
        grid-row-end: 6;
        margin-left: 20px;
    }
    
    .full-show-venue {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
        margin-left: 20px;
    }
    
    .full-show-venue-title {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }

}

@media (min-width: 576px) and (max-width: 767px) {
    .full-show-flyer-div {
        width: 235px;
    }
    
    .full-show-grid-container {
        grid-template-columns: 216px 226px 55px;
    }

    .full-show-info-border {
        width: 500px;
    }
    
    .full-show-info-container {
        width: 500px;
    }

    .full-show-set-list-div {
        width: 235px;
    }

}