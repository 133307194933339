.profile-date-link {
    white-space: nowrap;
}
.profile-date-link:link {
    color: black;
    text-decoration: none;
}
.profile-date-link:visited {
    color: black;
    text-decoration: none;
}
.profile-date-link:hover {
    text-decoration: underline;
}
.profile-date-link:active {
    text-decoration: underline;
}

.profile-edit-button {
    outline: none;
    transition-duration: 0.3s;
    box-sizing: border-box;
    height: 45px;
    width: 100px;
    border-radius: 0;
    cursor: pointer;
    border: 1px black solid;
    background-color: black;
    overflow: hidden;
    position: relative;
    color: white;
    align-self: center;
    /* margin-left: 14px; */
    /* margin-bottom: 20px; */
}

.profile-edit-button:hover {
    background-color: white;
    color: black;
}

.profile-edit-button:after {
    content: "";
    background: rgb(197, 197, 197);
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px!important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}
  
.profile-edit-button:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

.profile-info-details {
    flex:1;
    line-height: 1.3;
    min-width: 0;
    overflow-wrap: break-word;
}

.profile-info-div {
    display: flex;
    margin: 15px 0;
}

.profile-info-link:link {
    color: black;
    text-decoration: none;
}
.profile-info-link:visited {
    color: black;
    text-decoration: none;
}
.profile-info-link:hover {
    text-decoration: underline;
}
.profile-info-link:active {
    text-decoration: underline;
}

.profile-info-type {
    width: 130px;
}

.profile-sub-container {
    max-width: 700px;
    margin: 0 auto;
}

@media (max-width: 399px) {

    .profile-button-div {
        display: flex;
        justify-content: center;
    }

    .profile-info-details {
        margin-top: 4px;
    }

    .profile-info-div {
        flex-direction: column;
    }

    .profile-sub-container {
        width: 300px;
    }
}

@media (min-width: 400px) and (max-width: 575px) {

    .profile-button-div {
        display: flex;
        justify-content: center;
    }

    .profile-info-details {
        margin-top: 4px;
    }

    .profile-info-div {
        flex-direction: column;
    }
}
