html {
	font-size: 16px;
	font-family: 'Raleway', sans-serif;
}

button {
	font-family: 'Raleway', sans-serif;
	font-size: 16px;
	padding: 0;
	color: #000;
}

input {
	font-size: 1rem;
	font-family: 'Raleway', sans-serif;
	border-radius: 0;
}

select {
	font-size: 1rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type='text'] {
	-webkit-appearance: none;
}

h1 {
	font-size: 2.2rem;
	font-family: 'RobotoMono', sans-serif;
	text-transform: uppercase;
	letter-spacing: .44rem;
}

h2 {
	font-size: 1.8rem;
	font-family: 'RobotoMono', sans-serif;
	text-transform: uppercase;
	letter-spacing: .35rem;
}

h4 {
	font-size: 1.4rem;
}

#root {
	padding-left: calc(100vw - 100%);
}

@font-face {
	font-family: "RobotoMono";
	src: local("RobotoMono"), url("./fonts/RobotoMono-VariableFont_wght.ttf") format("truetype");
}

@font-face {
	font-family: "Raleway";
	src: local("Raleway"), url("./fonts/Raleway-VariableFont_wght.ttf") format("truetype");
}

@media (max-width: 575px) {
	#root {
		padding-left: 0;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	h2 {
		font-size: 1.6rem;
		letter-spacing: .3rem;
	}
}