.child-header-div {
    display: inline-block;
}

.fadeIn {
    opacity:1;
    transition: opacity 0.5s;

}

.fadeOut {
    opacity:0;
    transition: opacity 1.75s;

}

.main-header-div {
    display: flex;
    justify-content: center;
}

.no-shows-para {
    text-align: center;
}

.sub-root {
    width: 900px;
    margin: 0 auto;
    padding-bottom: 15px;
}

.timeline-div {
    /* margin-top: -15px; */
    width: 800px;
    margin: -15px auto 0 auto;
    /* display: none; */
    border: 0px solid white;
    box-shadow: 0px 0px 1px rgba(255, 255, 255, 0.52);
    transition: width 1s, border 1s, box-shadow 1s, transform 1s;
}

.timeline-div.mini {
    width: 200px;
    box-shadow: 5px 10px 1px black;
    border: 1px solid;
    /* transform: translate(-150%,-100%); */
    transform: translate(-300px,-115px);
    cursor: pointer;
}

.timeline-div.mini #timeline {
    pointer-events: none;
}

@supports (-webkit-text-stroke: 1px black) {
	.outline {
	  -webkit-text-stroke: 1px black;
	  -webkit-text-fill-color: rgba(255, 255, 255,0);
	}
}

@media (max-width: 399px) {
    h1 {
        font-size: 1.5rem;
    }
    .sub-root {
        width: 310px;
    }
    .timeline-div {
        width: 270px;
        margin: -4px auto 0 auto;
        transition: width 1s, border 1s, box-shadow 1s, transform 1s, background-color 1s cubic-bezier(.92,.19,.72,.28);
    }
    .timeline-div.mini {
        width: 150px;
        box-shadow: 3px 7px 1px black;
        transform: translate(-70px,-87px);
        background-color: white;
        transition: width 1s, border 1s, box-shadow 1s, transform 1s;
    }
}

@media (min-width: 400px) and (max-width: 575px) {
    h1 {
        font-size: 1.6rem;
    }
    .sub-root {
        width: 374px;
    }
    .timeline-div {
        width: 350px;
        margin: -8px auto 0 auto;
        transition: width 1s, border 1s, box-shadow 1s, transform 1s, background-color 1s cubic-bezier(.92,.19,.72,.28);
    }
    .timeline-div.mini {
        width: 150px;
        box-shadow: 3px 7px 1px black;
        transform: translate(-100px,-87px);
        background-color: white;
        transition: width 1s, border 1s, box-shadow 1s, transform 1s;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    h1 {
        font-size: 1.8rem;
    }
    .sub-root {
        width: 550px;
    }
    .timeline-div {
        width: 530px;
    }
    .timeline-div.mini {
        width: 150px;
        box-shadow: 3px 7px 1px black;
        transform: translate(-200px,-87px);
    }
}

@media (min-width: 768px) and (max-width: 950px) {
    h1 {
        font-size: 2rem;
    }
    .sub-root {
        width: 720px;
    }
    .timeline-div {
        width: 700px;
    }
    .timeline-div.mini {
        transform: translate(-260px,-105px);
    }
}