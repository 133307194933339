.forgot-password-box {
    max-width: 350px;
    height: 350px;
    border: black 1px solid;
    margin: 0 auto;
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
}

.forgot-password-button {
    outline: none;
    transition-duration: 0.3s;
    box-sizing: border-box;
    height: 50px;
    width: 200px;
    border-radius: 0;
    cursor: pointer;
    border: 1px black solid;
    background-color: black;
    overflow: hidden;
    position: relative;
    color: white;
    /* margin-bottom: 20px; */
}

.forgot-password-button:hover {
    background-color: white;
    color: black;
}

.forgot-password-button:after {
    content: "";
    background: rgb(197, 197, 197);
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px!important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}
  
.forgot-password-button:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

.forgot-password-header-div {
    text-align: center;
}

.forgot-password-input {
    width: 200px;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid black;
    outline:none;
    height: 50px;
}

.forgot-password-instructions-div {
    text-align: center;
}

.forgot-password-link:link {
    color: black;
    text-decoration: none;
}
.forgot-password-link:visited {
    color: black;
    text-decoration: none;
}
.forgot-password-link:hover {
    text-decoration: underline;
}
.forgot-password-link:active {
    text-decoration: underline;
}

.forgot-password-root {
    padding: 14px;
}